@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
.home__container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__container > * {
  margin-bottom: 10px;
}
.home__header {
  margin-bottom: 30px;
}
.username__input {
  padding: 10px;
  width: 50%;
}
.home__cta {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #607eaa;
  color: #f9f5eb;
  outline: none;
  border: none;
  border-radius: 5px;
}
.chat {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.chat__sidebar {
  height: 100%;
  background-color: #f9f5eb;
  flex: 0.2;
  padding: 20px;
  border-right: 1px solid #fdfdfd;
}
.chat__main {
  height: 100%;
  flex: 0.8;
}
.chat__header {
  margin: 30px 0 20px 0;
}
.chat__users > * {
  margin-bottom: 10px;
  color: #607eaa;
  font-size: 14px;
}
.online__users > * {
  margin-bottom: 10px;
  color: rgb(238, 102, 102);
  font-style: italic;
}
.chat__mainHeader {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f5eb;
}
.leaveChat__btn {
  padding: 10px;
  width: 150px;
  border: none;
  outline: none;
  background-color: #d1512d;
  cursor: pointer;
  color: #eae3d2;
}
.message__container {
  width: 100%;
  height: 80vh;
  background-color: #fff;
  padding: 20px;
  overflow-y: scroll;
}

.message__container > * {
  margin-bottom: 10px;
}
.chat__footer {
  padding: 10px;
  background-color: #f9f5eb;
  height: 10vh;
}
.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message {
  width: 80%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  outline: none;
  padding: 15px;
}
.sendBtn {
  width: 150px;
  background-color: green;
  padding: 10px;
  border: none;
  outline: none;
  color: #eae3d2;
  cursor: pointer;
}
.sendBtn:hover {
  background-color: rgb(129, 201, 129);
}
.message__recipient {
  background-color: #f5ccc2;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
}
.message__sender {
  background-color: rgb(194, 243, 194);
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  margin-left: auto;
  font-size: 15px;
}
.message__chats > p {
  font-size: 13px;
}
.sender__name {
  text-align: right;
}
.message__status {
  position: fixed;
  bottom: 50px;
  font-size: 13px;
  font-style: italic;
}

.chat-sidebar {
  background-color: #f4f5f7;
  border-right: 1px solid #e0e0e0;
  padding: 20px;
  height: 100vh;
  width: 300px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.chat-sidebar h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.chat-header {
  color: #555;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.chat-active-users {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.chat-users {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-card {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.user-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.user-avatar img {
  border-radius: 50%;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.user-name {
  flex: 1;
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}

.user-role {
  font-size: 0.85rem;
  color: #888;
  background-color: #eef;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 600;
}
/* Chat Header */
.chat__mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.leaveChat__btn {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.leaveChat__btn:hover {
  background-color: #d9363e;
  transform: scale(1.05);
}

/* Message Container */
.message__container {
  padding: 20px;
  background-color: #f9f9f9;
  height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Chat Messages */
.message__chats {
  display: flex;
  flex-direction: column;
}

.message__chats--sender {
  align-items: flex-end;
}

.message__chats--recipient {
  align-items: flex-start;
}

.chat__name {
  font-size: 0.9rem;
  font-weight: bold;
  color: #6c757d;
  margin-bottom: 5px;
}

.chat__bubble {
  max-width: 70%;
  padding: 12px 18px;
  font-size: 1rem;
  border-radius: 20px;
  word-wrap: break-word;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.chat__bubble--sender {
  background-color: #6a11cb;
  color: white;
  border-radius: 20px 20px 0 20px;
}

.chat__bubble--recipient {
  background-color: #e0e0e0;
  color: #333;
  border-radius: 20px 20px 20px 0;
}

/* Typing Status */
.message__status {
  font-size: 0.85rem;
  color: #757575;
  text-align: center;
  margin-top: 10px;
}

/* Scrollbar Styling */
.message__container::-webkit-scrollbar {
  width: 10px;
}

.message__container::-webkit-scrollbar-thumb {
  background: #6a11cb;
  border-radius: 10px;
}

.message__container::-webkit-scrollbar-thumb:hover {
  background: #4a0e9f;
}
/* Wrapper for Home Page */
.home__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  font-family: 'Roboto', sans-serif;
}

/* Form Container */
.home__container {
  background: #ffffff;
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

/* Form Header */
.home__header {
  font-size: 1.8rem;
  font-weight: bold;
  color: #4e54c8;
  margin-bottom: 30px;
}

/* Labels */
label {
  display: block;
  text-align: left;
  font-size: 1rem;
  color: #4e54c8;
  margin: 10px 0 5px;
}

/* Input Fields */
input {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

/* Input Focus State */
input:focus {
  outline: none;
  border-color: #4e54c8;
  box-shadow: 0px 0px 5px rgba(78, 84, 200, 0.5);
}

/* Sign-In Button */
.home__cta {
  background: #4e54c8;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.home__cta:hover {
  background: #3a3f9e;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .home__container {
    padding: 30px;
  }

  .home__header {
    font-size: 1.5rem;
  }
}
/* Chat Sidebar Container */
.chat-sidebar {
  width: 300px;
  max-width: 100%;
  background: #f4f4f9;
  border-right: 1px solid #ddd;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: 'Roboto', sans-serif;
}

/* Title Styling */
.chat-sidebar h2 {
  font-size: 1.5rem;
  color: #4e54c8;
  text-align: center;
  margin-bottom: 10px;
}

/* Header for Active Users */
.chat-header {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* User List Container */
.chat-users {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* No Users Message */
.no-users {
  text-align: center;
  font-size: 1rem;
  color: #888;
}

/* User Card */
.user-card {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* User Card Hover Effect */
.user-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* User Avatar */
.user-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background: #ddd;
}

/* User Info */
.user-info {
  flex-grow: 1;
}

/* User Name */
.user-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

/* User Role */
.user-role {
  font-size: 0.85rem;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .chat-sidebar {
    width: 100%;
    padding: 15px;
  }

  .chat-sidebar h2 {
    font-size: 1.25rem;
  }

  .user-card {
    padding: 8px;
  }

  .user-avatar img {
    width: 40px;
    height: 40px;
  }

  .user-name {
    font-size: 0.9rem;
  }

  .user-role {
    font-size: 0.75rem;
  }
}/* Chat Header */
.chat__mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #4e54c8;
  color: white;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat__mainHeader p {
  font-size: 1.2rem;
  margin: 0;
}

.leaveChat__btn {
  padding: 8px 12px;
  background-color: #ff5252;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.leaveChat__btn:hover {
  background-color: #e04848;
}

/* Message Container */
.message__container {
  padding: 20px;
  max-height: calc(100vh - 100px); /* Adjust based on header height */
  overflow-y: auto;
  background-color: #f9f9f9;
}

/* Message Chats */
.message__chats {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.message__chats--sender {
  align-items: flex-end;
}

.message__chats--recipient {
  align-items: flex-start;
}

/* Chat Name */
.chat__name {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 5px;
}

/* Chat Bubble */
.chat__bubble {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 0.95rem;
  line-height: 1.4;
  word-wrap: break-word;
}

.chat__bubble--sender {
  background-color: #4e54c8;
  color: white;
  border-radius: 15px 15px 0 15px;
}

.chat__bubble--recipient {
  background-color: #ddd;
  color: #333;
  border-radius: 15px 15px 15px 0;
}

/* Typing Status */
.message__status {
  text-align: center;
  font-size: 0.85rem;
  color: #666;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .chat__mainHeader p {
    font-size: 1rem;
  }

  .leaveChat__btn {
    padding: 6px 10px;
    font-size: 0.85rem;
  }

  .message__container {
    padding: 15px;
  }

  .chat__bubble {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

@media (max-width: 480px) {
  .chat__mainHeader {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .leaveChat__btn {
    width: 100%;
    max-width: 150px;
  }

  .chat__bubble {
    max-width: 90%;
    font-size: 0.85rem;
    padding: 8px 10px;
  }
}
.chat-sidebar {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: scroll;
  background-color: #f4f4f4;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.chat-header {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.chat-users {
  flex-grow: 1;
  overflow-y: auto;
}

.no-users {
  color: #888;
  text-align: center;
  margin-top: 20px;
}

.user-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.user-card:hover {
  background-color: #f0f0f0;
}

.user-avatar img {
  border-radius: 50%;
}

.user-info {
  margin-left: 10px;
}

.user-name {
  font-weight: bold;
  margin: 0;
}

.user-role {
  font-size: 12px;
  color: #666;
}

.social-links {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.social-links a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  transition: color 0.2s;
}

.social-links a:hover {
  color: #0056b3;
}

.notification-menu {
  position: relative;
  margin-top: 20px;
}

.notification-bell {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.notification-bell span {
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 3px 6px;
  margin-left: 5px;
}

.notification-popup {
  position: absolute;
  top: 30px;
  right: -58px;
  width: 300px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  padding: 15px;
}

.notification-popup h4 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.notification-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.notification-item h5 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.notification-item p {
  margin: 5px 0;
  font-size: 12px;
  color: #555;
}

.notification-item span {
  font-size: 10px;
  color: #888;
}

.no-notifications {
  color: #888;
  text-align: center;
}
